import React, { useState, useEffect } from 'react';
import Footer from '../../micro-site/Components/footer/Footer';
import Loader from '../Loader';
import KheloHeader from 'micro-site/Components/KheloHeader/KheloHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const LiveMatchVideos = () => {
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [matchDetails, setMatchDetails] = useState({});
  const [sports, setSports] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredMatches, setFilteredMatches] = useState([]); // Store filtered matches

   // Fetch sports
   const fetchSports = async () => {
    try {
      const response = await fetch(
        '/api/method/sfa_online.v1.microsite.get_data?doctype=Sport'
      );
      const data = await response.json();
      setSports(data.message || []);
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  };

  // Fetch event categories
  const fetchEventCategories = async () => {
    try {
        const filters = [['tournament', '=', "241"]];
        const response = await fetch(
          `/api/resource/Sport Event?fields=["*"]&filters=${JSON.stringify(filters)}&limit=1000`
        );
      const data = await response.json();
      setCategories(data.data || []);
    } catch (error) {
      console.error('Error fetching event categories:', error);
    }
  };

  const applyFilters = () => {
    let filtered = [...matches];

    if (selectedSport) {
      console.log(selectedSport)
      filtered = filtered.filter((match) => match.data.sport === selectedSport);
    }
    if (selectedGender) {
      console.log(selectedGender)
      filtered = filtered.filter((match) => match.data.gender === selectedGender);
      console.log(filtered)

    }
  
    if (selectedCategory) {
      filtered = filtered.filter((match) => match.data.sport_event === selectedCategory);
    }
    setFilteredMatches(filtered);
  };

  useEffect(() => {
    applyFilters();
  }, [selectedSport, selectedGender, selectedCategory, matches]);
  

  // Fetch live matches
  const fetchLiveMatches = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await fetch(
        '/api/method/sfa_online.v1.live_matches.match.get_live_matches'
      );
      const data = await response.json();
      setMatches(data.message || []);
      setFilteredMatches(data.message || []);
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
      console.error('Error fetching live matches:', error);
    }
  };

  // Fetch match details (teams) based on match_id
  const fetchMatchDetails = async (matchName) => {
    try {
      const response = await fetch(
        `https://admin.satgsportsforlife.in/api/method/sfa_online.v1.live_matches.match.get_live_match_title?match_id=${matchName}`
      );
      const data = await response.json();
      const participants = data.message || [];
  
      const formattedDetails = participants.map(p => ({
        institution_name: p.institution_name,
        participant: p.participant
      }));
  
      setMatchDetails((prevDetails) => ({
        ...prevDetails,
        [matchName]: formattedDetails,
      }));
    } catch (error) {
      console.error('Error fetching match details:', error);
    }
  };

  useEffect(() => {
    fetchLiveMatches();
    const clientId = Math.floor(1000000 + Math.random() * 9000000);
    const eventSource = new EventSource(
      `https://command-service.sfaplay.com/command-panel/v1/media/sse?client_id=${clientId}`
    );

    const handleSSEEvent = (type, data) => {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error('Error parsing data:', error);
          return;
        }
      }
      if (type === 'FRAPPE_STARTED') {
        setMatches((prevMatches) => {
          const matchIndex = prevMatches.findIndex((match) => match.data.name === data.name);
          if (matchIndex !== -1) {
            const updatedMatches = [...prevMatches];
            updatedMatches[matchIndex] = { type, data };
            return updatedMatches;
          }
          return [...prevMatches, { type, data }];
        });
      } else if (type === 'FRAPPE_ENDED') {
        setMatches((prevMatches) => prevMatches.filter((match) => match.data.name !== data.name));
      }
    };

    eventSource.onmessage = (event) => {
      const { type, data } = JSON.parse(event.data);
      handleSSEEvent(type, data);
    };

    eventSource.onerror = (event) => {
      console.error('Error occurred:', event);
      eventSource.close();
    };
    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, []);

  useEffect(() => {
    fetchLiveMatches();
    fetchSports();
    fetchEventCategories();
  }, []);

  useEffect(() => {
    if (matches.length > 0) {
      matches.forEach((match) => {
        fetchMatchDetails(match.data.name);
      });
    }
  }, [matches]);

  const handleSportChange = (e) => {
    setSelectedSport(e.target.value);
    setSelectedGender(''); 
    setSelectedCategory(''); 
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
    setSelectedSport('');
    setSelectedCategory('');
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSport('');
    setSelectedGender('');
  };


  return (
    <div className="w-screen">
      <KheloHeader />
      <section
        className="w-full flex flex-col"
        style={{ marginTop: 56, marginBottom: 56 }}
        id="live-matches"
      >
        <h3
          className="font-bold italic font-poppins text-center uppercase"
          style={{
            color: '#0D0D0D',
            fontSize: 24,
            marginBottom: 50,
          }}
        >
          Live Matches
        </h3>

        {/* Filters Row */}
        {!isLoading && (
          <div className="flex flex-wrap items-center gap-4 mb-6 px-3 ml-16">
            <select
              className="border p-2 rounded-md flex-grow"
              style={{ maxWidth: '150px' }}
              value={selectedSport}
              onChange={handleSportChange}
            >
              <option value="">Select Sport</option>
              {sports.map((sport) => (
                <option key={sport.name} value={sport.name}>
                  {sport.name}
                </option>
              ))}
            </select>
            <select
              className="border p-2 rounded-md flex-grow"
              style={{ maxWidth: '150px' }}
              value={selectedGender}
              onChange={handleGenderChange}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <select
              className="border p-2 rounded-md flex-grow"
              style={{ maxWidth: '250px' }}
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">Select Event Category</option>
              {categories.map((category) => (
                <option key={category.name} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="w-11/12 m-auto">
          <div
            className="grid grid-cols-1 md:grid-cols-3 justify-center gap-4 p-4 mx-auto"
            style={{ columnGap: 43, rowGap: 60 }}
          >
            {isLoading && (
              <div
                className="w-full flex items-center justify-center"
                style={{ marginLeft: '360px' }}
              >
                <Loader />
              </div>
            )}

            {isError && (
              <div className="text-center text-red-500">Failed to load live matches.</div>
            )}

            {/* Apply filtering only when filters are selected */}
            {!isLoading && filteredMatches.length > 0 ? (
              filteredMatches.map((match) => (
                <div key={match.name} className="flex flex-col gap-3">
                  <iframe
                    width="100%"
                    height="250"
                    src={match.data.live_video_link}
                    title={`Live Match: ${match.data.sport_event}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    style={{ borderRadius: '25px' }}
                  />
                  <p
                    className="font-poppins font-bold text-center"
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#000B27',
                    }}
                  >
                    {match.data.sport_event}
                  </p>

                  {/* Match Details */}
                  {matchDetails[match.data.name] && (
                    <div className="text-center">
                      {matchDetails[match.data.name].length <= 2 ? (
                        <p
                          className="font-poppins font-bold text-center"
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: 'red',
                          }}
                        >
                          {matchDetails[match.data.name]
                            .map((detail) => detail.institution_name)
                            .join(' VS ')}
                        </p>
                      ) : (
                        <>
                          <div className="flex justify-center items-center">
                            <p
                              className="font-poppins font-bold text-center mr-2"
                              style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: 'red',
                              }}
                            >
                              {matchDetails[match.data.name].length} Participants
                            </p>
                            <button
                              className="info-btn text-blue-500 hover:text-blue-700"
                              title={matchDetails[match.data.name]
                                .map((detail) => detail.participant)
                                .join(', ')}
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '18px',
                                padding: '0',
                                position: 'relative',
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                size="md"
                              />
                              <span className="sr-only">Info</span>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))
            ) : (
              !isLoading && (
                <div
                  className="w-full h-full flex justify-center items-center"
                  style={{ minHeight: '20px', marginLeft: '440px' }}
                >
                  <div className="text-gray-500 text-center">No matches found for the selected filters.</div>
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LiveMatchVideos;
