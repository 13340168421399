import React from 'react';
// import male from '../../assets/img/microsite/sports/Vectormale.svg';
import schedule from '../../assets/img/microsite/sports/Vectorschedule.svg';
import { useHistory } from 'react-router-dom';
import {
  getCheckIfEventisLiveWithRange,
  getCheckTimePassed,
  getDateInMonthDate,
  getDateInMonthDateYear,
} from '../../constants/DateFunctions';
import { faVenus, faMars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function Card({ data, redirectTo, variant, canShowDraws, index }) {
  // console.log(data);
  const history = useHistory();

  const useCheckIfEventPassed = () => {
    const endDateTimeStamp = data?.detail_tags[2]?.split(' - ')?.[1];
    return getCheckTimePassed(endDateTimeStamp);
  };

  // console.log(data);
  return (
    <div
      className={`
      h-full
      justify-between
    ${
      useCheckIfEventPassed()
        ? 'bg-gray-100 border-gray-300'
        : // : typeof index === 'number' && index % 2 === 0
          // ? 'bg-white border-blue-kheloBlue'
          ` ${variant !== 'subCategory' ? 'border-blue-kheloBlue' : 'border-gray-300'} bg-white`
    } 
      border cursor-pointer flex flex-col ${
        data.img ? 'w-full' : ''
      } rounded-lg relative px-4 md:px-4 py-4 md:py-5`}
      onClick={() => history.push(redirectTo)}
      data-cy="event_card"
    >
      {getCheckIfEventisLiveWithRange(
        data.detail_tags?.[2]?.split(' - ')?.[0],
        data.detail_tags?.[2]?.split(' - ')?.[1]
      ) && (
        <div className="absolute top-1 right-1 px-2 py-0.5 bg-green-50 text-green-450 text-xs rounded">
          Live
        </div>
      )}
      <div className="flex flex-col justify-between">
        <div
          className={`mt-2 md:mt-0 text-sm md:text-xl ${
            data.img ? 'order-2' : 'order-1 mt-1 h-full'
          } font-bold`}
        >
          <div
            className={`flex flex-col  ${
              !variant === 'subCategory' ? 'mt-2' : 'h-full justify-between'
            } `}
          >
            {variant === 'subCategory' ? (
              <>
                <span className="">{data.title}</span>
                <div className="flex items-center mt-2">
                  <span className="font-bold text-xxs md:text-xs">{data.age_group}-</span>
                  <span className="font-bold text-xxs md:text-xs">{data.sub_category}</span>
              </div>
              </>
            ) : null}
          </div>
        </div>

        <div
          className={`${data.tags ? 'mt-1 md:mt-2 text-xs order-2 text-gray-500 flex gap-2' : ''}`}
        >
          {data.tags
            ? data.tags.map((d, i) => {
                return (
                  d.icon !== 'age_group' && (
                    <div
                      data-cy="gender_age_group"
                      key={i}
                      className={`flex ${d.bg_color} ${d.color} py-0.5 md:py-1 md:px-2 px-1  gap-1.5 rounded items-center`}
                    >
                      {d.text?.toLowerCase() === 'male' ? (
                        <FontAwesomeIcon icon={faMars} />
                      ) : d.text?.toLowerCase() === 'female' ? (
                        <FontAwesomeIcon icon={faVenus} />
                      ) : (
                        ''
                      )}

                      {d.icon === 'age_group' ? (
                        <img
                          src={schedule}
                          alt="age_group"
                          className="md:w-3 md:h-3 w-2.5 h-2.5 "
                        />
                      ) : (
                        ''
                      )}
                      <p className={`md:text-xs text-xxs ${d.color}`}>{d.text}</p>
                    </div>
                  )
                );
              })
            : null}
        </div>
        {data.img ? (
          <div className="flex flex-col items-center max-h-26">
            <img
              src={data.img}
              alt="sports image"
              className="rounded-lg order-1 w-full h-auto bg-center"
            />
          </div>
        ) : null}
      </div>

      <div className="mt-2 text-xs md:text-sm text-gray-lighter">
        {data.img ? (
          <div className="flex flex-col h-full">
            {variant !== 'subCategory' ? (
              <div className="text-sm md:text-xl font-bold text-black">
                <span className="mt-4 text-lg">{data.title}</span>
                <span className="text-xxs md:text-xs">{data.sub_category}</span>
              </div>
            ) : null}
            {/* <span>{data.detail_tags[1]}</span> */}
            {/* <span className="mx-1">
              <span className="hidden md:inline-block">•</span>
              <span className="md:hidden inline-block"></span>
            </span> */}
            <div className="text-left text-xs">
              {data.detail_tags?.[2]?.split(' - ')?.[0] !==
              data.detail_tags?.[2]?.split(' - ')?.[1] ? (
                <>
                  <div className="inline-block">
                    {getDateInMonthDate(data.detail_tags?.[2]?.split(' - ')?.[0])}
                  </div>
                  <span className="inline-block">&nbsp;-&nbsp;</span>
                  {/* <br/> */}
                  <div className="inline-block">
                    {getDateInMonthDateYear(data.detail_tags?.[2]?.split(' - ')?.[1])}
                  </div>
                </>
              ) : (
                getDateInMonthDateYear(data.detail_tags?.[2]?.split(' - ')?.[1])
              )}
              {/* {testDate.test(data.detail_tags[2])
                ? getDateInMonthDateYear(data.detail_tags[2].split(' - ')[0]) +
                  ' - ' +
                  getDateInMonthDateYear(data.detail_tags[2].split(' - ')[1])
                : data.detail_tags[2]} */}
            </div>
            <div>{data.detail_tags[0]}</div>
            {/* <br /> */}

            {/* <br /> */}
            <div className="line-clamp-2 break-all h-12">{data?.venue}</div>
          </div>
        ) : variant !== 'subCategory' ? (
          <div className="flex flex-col h-full">
            <div className="text-sm md:text-xl font-bold text-black">
              <span className="mt-4 text-lg">{data.title}</span>
              <span className="text-xxs md:text-xs">{data.sub_category}</span>
            </div>
          </div>
        ) : null}

        {/* Redirection to Digital */}
        {variant === 'subCategory' && canShowDraws?.message && (
          <div className="flex justify-center items-center mt-2">
            <button
              className="text-base p-1 font-bold text-gray-750 cursor-pointer rounded-lg border border-gray-750 w-full md:w-3/4"
              onClick={() => {
                history.push(
                  `/public/score/${data.type.toLowerCase()}/${encodeURIComponent(data.sport_event)}`
                );
              }}
            >
              View Fix/Results
            </button>
          </div>
        )}

        {/* Redirection to Digital */}
      </div>

      {/* <div className={`flex w-full h-full ${data.img ? '' : 'flex-col items-center'}`}>
        {data.registration_open ? (
          <div>
            <div
              className={`mt-4 md:mt-7 text-xs md:text-sm font-bold ${
                data.img ? 'text-left' : 'text-center'
              }`}
            >
              {data.price} <span className="font-normal text-gray-450">/person</span>
            </div>
            <div className="mt-1 md:mt-4">
              <button className="text-xs md:text-base border border-gray-900 py-1 md:py-1.5 px-3 lg:px-6 font-bold m-auto w-full rounded-lg hover:bg-gray-800 hover:text-white transition-colors transition-delay-200">
                Register Now
              </button>
            </div>
          </div>
        ) : (
          <div className=" h-full flex items-end	">
            {data.status !== null ? (
              <div
                className={`${data.status.color} ${data.status.bg_color} md:mt-7 mt-3.5 text-xxs md:text-xs py-1 px-1.5 rounded-md inline-block`}
              >
                {data.status.text}
              </div>
            ) : null}
          </div>
        )}
      </div> */}
    </div>
  );
}
