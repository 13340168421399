import React, { useState, useEffect } from 'react';
import Rectangle from '../../assets/access-profile/download.svg';
// import CMLogo from '../../assets/access-profile/cm new logos N.svg';
import CMLogo from '../../assets/img/cm-trophy/logo.png';

import './css/formStyle.css';
import Navbar from '../navbar/navbarWithNotificationandProfile';

const AccessProfile = () => {
  const [fetchAthletes, setFetchAthletes] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // State for form selections
  const [tournamentCategory, setTournamentCategory] = useState('');
  const [ageGroup, setAgeGroup] = useState('');
  const [districtRegional, setDistrictRegional] = useState('');
  const [sport, setSport] = useState('');
  const [gender, setGender] = useState('');
  const [selectEvent, setSelectEvent] = useState('');

  // State for options
  const [tournamentCategories, setTournamentCategories] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [districtRegionals, setDistrictRegionals] = useState([]);
  const [sports, setSports] = useState([]);
  const [genders] = useState(['Male', 'Female']);
  const [selectEvents, setSelectEvents] = useState([]);
  const districtRegionalsArr = [];

  districtRegionals.sort((a, b) => districtRegionalsArr.push(a.institution));

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch tournament categories
        const tournamentResponse = await fetch(
          '/api/method/sfa_online.v1.microsite.get_data?doctype=Tournament'
        );
        const tournamentData = await tournamentResponse.json();
        setTournamentCategories(tournamentData.message || []);

        // Fetch district regionals
        const districtResponse = await fetch(
          '/api/method/sfa_online.v1.microsite.get_data?doctype=Institution'
        );
        const districtData = await districtResponse.json();
        setDistrictRegionals(districtData.message || []);

        // Fetch sports
        const sportsResponse = await fetch(
          '/api/method/sfa_online.v1.microsite.get_data?doctype=Sport'
        );
        const sportsData = await sportsResponse.json();
        const sortedSports = (sportsData.message || []).sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setSports(sortedSports || []);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchAgeGroups = async () => {
      try {
        // Fetch age groups
        const ageGroupResponse = await fetch(
          `/api/method/sfa_online.v1.digital_scoring.get_age_group?tournament=241&sport=${sport}&gender=${gender}`
        );
        const ageGroupData = await ageGroupResponse.json();
        console.log(ageGroupData)
        setAgeGroups(ageGroupData.message || []);
      
      } catch (error) {
        console.error('Error fetching options:', error);
      }
      };
        fetchAgeGroups();
      }, [sport, gender]);


  useEffect(() => {
    // Fetch events when tournamentCategory, gender, or sport changes
    const fetchEvents = async () => {
      // if (tournamentCategory) {
        try {
          // Build the filters array
          const filters = [['tournament', '=', "241"]];
          if (gender) {
            filters.push(['gender', '=', gender]);
          }
          if (ageGroup) {
            filters.push(['age_group', '=', ageGroup]);
          }
          if (sport) {
            filters.push(['sport', '=', sport]);
          } 
          // Construct the API URL with the filters
          const eventsResponse = await fetch(
            `/api/resource/Sport Event?fields=["*"]&filters=${JSON.stringify(filters)}&limit=1000`
          );

          const eventsData = await eventsResponse.json();
          setSelectEvents(eventsData.data || []);
        } catch (error) {
          console.error('Error fetching events:', error);
        }
      
    };

    fetchEvents();
  }, [tournamentCategory, gender, sport, ageGroup]); // Add gender and sport to the dependency array

  const filteredAthletes = athletes.filter((athlete) => {
    const name = athlete.first_name || ''; // Fallback to an empty string if name1 is undefined
    return name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission

    const data = {
      tournamentCategory,
      districtRegional,
      sport,
      gender,
      selectEvent,
      // ageGroup
    };

    setFetchAthletes(true);
    getAthletes(data);
  };

  const getAthletes = async (data) => {
    const queryParams = [
      data.tournamentCategory ? `tournament=${encodeURIComponent(data.tournamentCategory)}` : '',
      data.districtRegional ? `district=${encodeURIComponent(data.districtRegional)}` : '',
      // data.ageGroup ? `age_group=${encodeURIComponent(data.ageGroup)}` : '',
      data.sport ? `sports=${encodeURIComponent(data.sport)}` : '',
      data.gender ? `gender=${encodeURIComponent(data.gender)}` : '',
      data.selectEvent ? `sport_event=${encodeURIComponent(data.selectEvent)}` : '',
    ]
      .filter(Boolean)
      .join('&');

    const finalQueryString = `?${queryParams}`;
    const response = await fetch(
      `/api/method/sfa_online.v1.microsite.get_athletes_by_tournament${finalQueryString}`,
      { method: 'GET' }
    );
    const result = await response.json();
    setAthletes(result.message);
  };

  const autoLoginRedirect = async (sfaId) => {
    try {
      const jsonData = { user: sfaId, redirect_to: '/athlete/profiles' };
      const response = await fetch('/api/method/sfa_online.v1.microsite.create_sign_in_link', {
        method: 'POST',
        body: JSON.stringify(jsonData),
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      const fullUrl = window.location.origin + data.message;
      window.location.href = fullUrl;
    } catch (error) {
      console.error('There was an error with the auto-login:', error);
    }
  };

  return (
    <div className={'flex flex-col h-screen'}>
      <Navbar side />
      <section className="flex flex-1 overflow-hidden h-full">
        <div className="relative w-2/4 h-full hidden md:block">
          <img className="h-full object-cover" src={Rectangle} alt="Background" />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img src={CMLogo} alt="CM Logo" />
          </div>
        </div>
        <div className="w-full md:w-2/5 flex flex-col justify-center items-center gap-3 scroll-none p-4">
    {!fetchAthletes ? (
      <div className="w-full">
        <div className="mb-3">
          <p className="text-sm">
            <b>Access your profile</b>
          </p>
          <p className="text-xs">Select filters and search your name to access your profile</p>
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <select
            value={districtRegional}
            onChange={(e) => setDistrictRegional(e.target.value)}
            className="p-2 border rounded-md text-sm"
          >
            <option value="">Select District</option>
            {districtRegionalsArr.sort().map((district, index) => (
              <option key={index} value={district}>
                {district}
              </option>
            ))}
          </select>

          <select value={sport} onChange={(e) => setSport(e.target.value)} className="p-2 border rounded-md text-sm">
            <option value="">Select Sport</option>
            {sports.map((sport) => (
              <option key={sport.id} value={sport.name}>
                {sport.name}
              </option>
            ))}
          </select>

          <select value={gender} onChange={(e) => setGender(e.target.value)} className="p-2 border rounded-md text-sm">
            <option value="">Select Gender</option>
            {genders.map((gender) => (
              <option key={gender} value={gender}>
                {gender}
              </option>
            ))}
          </select>

          <select value={ageGroup} onChange={(e) => setAgeGroup(e.target.value)} className="p-2 border rounded-md text-sm">
          <option value="">Select Age Group</option>
          {ageGroups.map((ageGroupName, index) => (
            <option key={index} value={ageGroupName}>
              {ageGroupName}
            </option>
          ))}
        </select>

        <select
          value={selectEvent}
          onChange={(e) => setSelectEvent(e.target.value)}
          required
          className="p-2 border rounded-md text-sm"
        >
          <option value="">Select Event</option>
          {selectEvents.map((event) => (
            <option key={event.id} value={event.name}>
              {event.name}
            </option>
          ))}
        </select>

        <button
          type="submit"
          style={{
            padding: '10px',
            color: '#3b82f6',
            border: '2px solid #3b82f6',
            backgroundColor: '#e5e7eb',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '14px', // Smaller font size
          }}
        >
          Search
        </button>
      </form>
    </div>
  ) : (
    <div className="w-full">
      <div className="mb-3">
        <p className="text-sm">
          <b>Search your profile</b>
        </p>
        <p className="text-xs">Enter your full name to access your profile</p>
      </div>
      <div style={{ width: '100%' }}>
        <input
          placeholder="Search Your Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginBottom: '12px',
            background: 'gainsboro',
            fontSize: '14px', // Smaller font size
          }}
        />
        <p style={{ color: '#b3b3b3', fontSize: '12px' }}>Name</p>
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
            overflowY: 'scroll',
            height: '45vh', // Reduce height
            padding: '8px',
          }}
        >
          <div className="flex justify-center">
            {filteredAthletes.length === 0 ? <p>Record not found</p> : <></>}
          </div>
          {filteredAthletes.map((athlete) => (
            <div
              className="w-full"
              onClick={() => autoLoginRedirect(athlete.sfa_id)}
              key={athlete.sfa_id}
              style={{
                width: '80%',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                padding: '8px',
                marginBottom: '8px',
                borderRadius: '8px',
                backgroundColor: '#fff',
                cursor: 'pointer',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: '14px' }}>{athlete.first_name}</p>
              <div className="flex gap-2">
                <p style={{ color: '#666', margin: '4px 0', fontSize: '12px' }}>{athlete.gender}</p>
                <p style={{ color: '#666', margin: '4px 0', fontSize: '12px' }}>{athlete.sfa_id}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-3 w-1/4">
          <button
            type="button"
            onClick={() => setFetchAthletes(false)}
            style={{
              width: '100%',
              padding: '10px',
              color: '#3b82f6',
              border: '1px solid #3b82f6',
              backgroundColor: '#e5e7eb',
              borderRadius: '8px',
              cursor: 'pointer',
              fontSize: '14px',
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  )}
</div>

      </section>
    </div>
  );
};

export default AccessProfile;
