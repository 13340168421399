// import { useState } from 'react';
// import CMTrophyLogo from '../../../assets/img/cm-trophy/logos.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import logo from '../../../assets/img/cm-trophy-home-page/new/CM_CUP_.png';

import useCustomStyling from 'hooks/useCustomStyling';
const HeaderLogo = () => {
  // const [currentImg] = useState(CMTrophyLogo);
  const { isMobile } = useCustomStyling();
  return (
    // <div>
    //   <img src={currentImg} className={'h-14 hidden lg:inline-block cursor-pointer'} alt={''} />
    //   {/* <img src={KheloIndiaLogo} className={'h-12 lg:hidden cursor-pointer'} alt={''} /> */}
    // </div>

    <Link to="/" className="flex items-center gap-2 justify-start">
      <img
        src={logo}
        alt="Emblem"
        // className=" rounded-full "
        style={{ width: isMobile ? '140px' : '160px', height: isMobile ? '50px' : '55px', marginLeft: isMobile ? '30px' : '50px' }}
      />
    </Link>
  );
};

export default HeaderLogo;
