/**
 * A utility to support fetch functionality for both web and native platforms(Android/iOS)
 */

import { Http as CapHttp } from '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';
import { Directory } from '@capacitor/filesystem';

const Http = class {
  // creates a constructor which sets the base url of instance
  constructor() {
    this.baseUrl = Capacitor.isNativePlatform()
      ? process.env.REACT_APP_MOBILE_API_BASE_URL
      : process.env.REACT_APP_WEB_API_BASE_URL;
    this.cookies = localStorage.getItem('cookies');
    this.baseResourceUrl = Capacitor.isNativePlatform()
      ? process.env.REACT_APP_MOBILE_API_RESOURCE_BASE_URL
      : process.env.REACT_APP_WEB_API_RESOURCE_BASE_URL;
  }

  /**
   * Set application cookies in the browser
   * @param {string} cookies
   */
  setCookies(cookies) {
    this.cookies = cookies;
    localStorage.setItem('cookies', cookies);
  }

  /**
   * Clear application cookies in the browser
   */
  clearCookies() {
    this.cookies = null;
    localStorage.removeItem('cookies');
  }

  /**
   * Async method to login to the application also on successful login sets cookies received from response headers
   * @param {string} username
   * @param {string} password
   * @returns {object}
   */
  async login(username, password) {
    if (Capacitor.isNativePlatform()) {
      const res = await CapHttp.post({
        url: `${this.baseUrl}/login`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { usr: username, pwd: password },
      });

      if (res.status !== 200) {
        throw new Error('Oops, something went wrong!');
      } else {
        this.setCookies(res.headers['Set-Cookie']);
        return res.data;
      }
    } else {
      const formData = new FormData();
      formData.append('usr', username);
      formData.append('pwd', password);

      const requestOptions = {
        method: 'POST',
        body: formData,
        credentials: 'include',
      };

      return await fetch(`${this.baseUrl}/login`, requestOptions).then((response) => {
        if (response.status !== 200) throw new Error('Username and password does not match.');
        else return response.json();
      });
    }
  }

  /**
   * Async method to logout by clearing all cookies
   * @returns {object}
   */
  async logout() {
    if (Capacitor.isNativePlatform()) {
      const res = await CapHttp.get({
        url: `${this.baseUrl}/logout`,
      });

      if (res.status !== 200) {
        throw new Error('Oops, something went wrong!');
      } else {
        this.clearCookies();
        return res;
      }
    } else {
      return await fetch(`${this.baseUrl}/logout`, {
        method: 'GET',
      });
    }
  }

  /**
   * Http request to make API call for both web and native platforms(Android/iOS)
   * @param {{apiEndpoint: string, headers?: object, method?: string, options?: object}} object
   * @returns {object}
   */

  async fetchFormio({
    apiEndpoint,
    headers = { 'Content-Type': 'application/json' },
    method = 'GET',
    options = null,
  }) {
    if (Capacitor.isNativePlatform()) {
      const res = await CapHttp.request({
        url: `${apiEndpoint}`,
        headers: {
          ...headers,
          Cookie: this.cookies,
        },
        method,
        data: options,
      });
      if (res.status !== 200) throw new Error('API ERROR!');
      else return res.data;
    } else {
      const requestOptions = {
        headers,
        method,
      };
      if (options) {
        requestOptions.body = JSON.stringify(options);
      }
      return await fetch(`${apiEndpoint}`, requestOptions).then((res) => res.json());
    }
  }
  /**
   * fetch method for the http instance, uses the cookie and automatically appends it to headers on each request
   * @param {{apiEndpoint:string,headers:object,method:string,options:object|null}}
   *
   * @returns
   */

  async fetch({
    apiEndpoint,
    headers = { 'Content-Type': 'application/json' },
    method = 'GET',
    options = null,

  }) {
    if (Capacitor.isNativePlatform()) {
      const res = await CapHttp.request({
        url: `${this.baseUrl}/${apiEndpoint}`,
        headers: {
          ...headers,
          Cookie: this.cookies,
        },
        method,
        data: options,
      });
      if (res.status !== 200) throw new Error('API ERROR!');
      else return res.data;
    } else {
      const requestOptions = {
        headers,
        method, 
        timeout: 100000
      };
      if (options) {
        requestOptions.body = JSON.stringify(options);
      }
      return await fetch(`${this.baseUrl}/${apiEndpoint}`, requestOptions).then((res) =>
        res.json()
      );
    }
  }

  async fetchDB({
    apiEndpoint,
    headers = { 'Content-Type': 'application/json' },
    method = 'GET',
    options = null,
  }) {
    if (Capacitor.isNativePlatform()) {
      const res = await CapHttp.request({
        url: `${this.baseResourceUrl}/${apiEndpoint}`,
        headers: {
          ...headers,
          Cookie: this.cookies,
        },
        method,
        data: options,
      });
      if (res.status !== 200) throw new Error('API ERROR!');
      else return res.data;
    } else {
      const requestOptions = {
        headers,
        method,
      };
      if (options) {
        requestOptions.body = JSON.stringify(options);
      }
      return await fetch(`${this.baseResourceUrl}/${apiEndpoint}`, requestOptions).then((res) =>
        res.json()
      );
    }
  }

  /**
   * Http request to upload file
   * @param {{apiEndpoint: string, name: string, file: object, options?: object}} object
   * @returns {object}
   */
  async uploadFile({ apiEndpoint, name, file, options = null }) {
    if (Capacitor.isNativePlatform()) {
      // convert file object to blob
      const fileBlob = new Blob([file], { type: file.type });

      const res = await CapHttp.uploadFile({
        url: `${this.baseUrl}/${apiEndpoint}`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Cookie: this.cookies,
        },
        blob: fileBlob,
        name,
        method: 'POST',
        filePath: name,
        fileDirectory: Directory.Documents,
      });

      if (res.status !== 200) throw new Error('API ERROR!');
      else return res.data;
    } else {
      const formData = new FormData();
      formData.append('file', file, name);

      return await fetch(`${this.baseUrl}/${apiEndpoint}`, {
        method: 'POST',
        body: formData,
        ...options,
      }).then((res) => res.json());
    }
  }
};

// Export class instance
export default new Http();
